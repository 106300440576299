@import "../../scss/components/form";
@import "../../scss/utils/formInput";

.ms-form-group {
    .ms-currency-control {
        &.has-error {
            border: 1px solid #E30000;
            border-radius: 5px;
        }
        &:focus-within {
            border: 1px solid $medispend-green;
        }
        position: relative;
        border: 1px solid $medispend-gray-400;
        border-radius: 5px;
        display: flex;
        gap: 3px;
        .ms-button.md {
            padding: 0;
        }
        .ms-currency-wrapper {
            margin-right: 20px;
            display: flex;
            align-items: center;
            .dropdown-menu {
                margin-left: -90px!important;
            }

            .ms-form-select {
                line-height: 16px;
            }
        }
        .ms-button.btn .ms-button-title {
            justify-content: center;
            .dropdown-title {
                width: auto;
            }
        }

        .ms-input {
            @extend .form-input;
            border: none;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 5px 0 5px 16px;
            &:focus{
                outline: none;
            }
            &::placeholder {
                color: $medispend-gray-400;
                font-size: 14px;
            }
            &:disabled::before {
                cursor: not-allowed;
            }

            &:disabled {
                opacity: 0.6;
            }
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
