@import "../../scss/variables/all";

.ms-file-uploader-container {
    .ms-file-uploader-fileName {
        background: $medispend-gray-200;
        border-radius: $ms-bradius;
        padding: 8px 16px;
        margin-bottom: 16px;
        width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .ms-file-uploader {
        border: 1px dashed $medispend-gray-400;
        border-radius: 5px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
