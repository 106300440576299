@import "../../scss/variables/all";

.ms-radio-button {
    display: flex;
    &-item {
        margin-right: 12px;
        input {
            display: none;
        }
    }

    &-item label {
        border-radius: 5px;
        padding: 12px;
        border: 1px solid $medispend-gray-200;
        color: $medispend-gray-900;
        cursor: pointer;
    }

    &-item input:checked + label {
        background-color: $medispend-light-green;
        color: $medispend-green;
        border: 1px solid $medispend-green;

        &:hover {
            opacity: 0.8;
        }
    }

    &-item input:disabled + label {
        opacity: 0.7;
        cursor: not-allowed;
    }

}
