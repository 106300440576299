@import "../../lib/common/src/scss/MediSpend";
@import "../../lib/common/src/scss/variables/colors";
@import "../../../../lib/common/src/scss/utils/formInput";
@import "../../../../lib/common/src/scss/utils/selectButton";
@import "../../../admin-common/src/scss/table";

body {
    background: #fff;
}

h1 {
    color: $medispend-green;
}

.editable-screen-content {
    min-height: 80vh;
    display: flex;
    align-items: stretch;
    margin-bottom: 20px;

    h2 {
        font-size: 20px;
    }

    &>div{
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.03);
        border-radius: 5px;
        padding: 20px;
    }

    .section-view {
        height: 100%;
        min-height: 80vh;
        flex-grow: 2.5;
        margin-right: 29px;
        background-color: white;
        display: flex;


        .header {
            display: flex;
            justify-content: space-between;

            .ms-form-group {
                margin-bottom: 0;
            }
        }

        h2 {
            margin-bottom: 20px;
        }
        h3 {
            font-size: 13px;
            font-weight: 500;
        }
        .ms-select-button {
            @extend .select-button;
            min-width: 218px;
            .ms-button {
                padding: 3px 16px;
            }
        }

    }

    .section-details {
        flex-grow: 1;
        background: $medispend-gray-200;
        width: 25%;
        min-width: 330px;
        h2 {
            margin-bottom: 35px;
        }
        .ms-text-search {
            float: none;
            margin-top: 13px;
            width: 100%;
            max-width: 350px;
            input {
                width: 250px;
            }
        }
        form {
            .fields-wrapper {
                display: grid;
                grid-template-columns: minmax(max-content, min-content) minmax(200px, 1fr);
                row-gap: 17px;
                label {
                    text-align: end;
                    margin-right: 7px;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 16px;
                    color: $medispend-gray-900;
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 5px;
                }
                .ms-input {
                    @extend .form-input;
                    border: 1px solid $medispend-gray-500;
                    border-radius: 3px;
                    &:focus, &:active, &:hover {
                        outline: none;
                    }
                }
                .ms-select-button {
                    @extend .select-button;
                    width: 100%;
                }
                .error-message {
                    font-size: 13px;
                }
                .section-fields-container {
                    padding: 0;
                }
            }
            .ms-button.sm {
                float: right;
                margin-top: 48px;
            }
            .ms-button.btn-green {
                margin-left: 10px;
            }
        }
        .available-fields-wrapper {
            background-color: $medispend-gray-300;
            border-radius: 4px;
            width: 100%;
            max-width: 350px;
            margin-top: 22px;
            .available-field {
                font-size: 13px;
                font-weight: 500;
                height: 30px;
                border-bottom: 1px solid $medispend-gray-200;
                display: flex;
                align-items: center;
                padding: 0 18px;
                &__label {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                button {
                    border: none;
                    background: none;
                }
                .fa-plus-circle {
                    color: $medispend-green;
                }
            }
        }
    }
}

.section-fields {
    border: 1px solid $medispend-gray-500;
    border-radius: 5px;
    width: fit-content;
    margin-top: 23px;
    h3 {
        border-bottom: 1px solid $medispend-gray-500;
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 7px;
        background-color: $medispend-gray-200;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        span {
            font-size: 14px;
            font-style: italic;
            font-weight: 400;
        }
    }
}

.section-fields-container {
    padding: 14px 14px;
    column-gap: 5px;
    max-width: 1100px;
    margin: 0 auto;
    grid-auto-rows: min-content;
    .empty-section-field {
        margin: 0;
        height: 10px;
    }
    .section-field {
        position: relative;
        border: 1px solid $medispend-gray-500;
        border-radius: 3px;
        font-size: 13px;
        height: 30px;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: move;
        &__label {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .icons-area {
            display: flex;
            height: 100%;
            .arrows-wrapper {
                display: flex;
                background-color: #EDF0F2;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                span {
                    color: $medispend-gray-600;
                    padding: 5px;
                }
            }

            .info-icon {
                color: $medispend-blue;
            }
        }

        button {
            border: none;
            background: none;
            display: flex;
            align-items: center;
            img {
                width: 13px;
                height: 13px;
            }
        }
        .resize-handle {
            border-bottom: 0 solid transparent;
            border-right: 15px solid $medispend-gray-500;
            border-top: 15px solid transparent;
            bottom: 0;
            cursor: ew-resize;
            display: inline-block;
            height: 0;
            position: absolute;
            right: 0;
            width: 0;
        }
    }
    .active-field {
        border: 1px solid $medispend-green;
    }
}
