@import "../../scss/variables/all";

.ms-alert-messages {

  .ms-alert-message-regular {
    color: $medispend-red;
  }
  .ms-alert-message-soft {
    color: $medispend-orange
  }

  position: sticky;
  top:$header-height;
  background-color: #fff;
  padding: 10px 0;
  z-index: 1;
  font-size: 14px;

  p:last-child {
    margin-bottom: 0;
  }
}