@import "../../scss/variables/colors";

.ms-hint {
  display: flex;
  padding: 15px;
  font-size: 14px;
  border-radius: 5px;
  color: $ms-badge-text-green;
  background-color: $ms-badge-background-green;
  column-gap: 20px;
  margin-bottom: 16px;
}