@import "~@medispend/common/src/scss/components/form";
@import "~@medispend/common/src/scss/utils/formInput";
@import "~@medispend/common/src/scss/utils/selectButton";

.ms-form-group {
    .ms-input {
        @extend .form-input;
    }
}

.add-values {
    font-size: 13px;
    font-weight: 500;
    height: 30px;
    border-bottom: 1px solid $medispend-gray-200;
    display: flex;
    align-items: center;
    button {
        border: none;
        background: none;
    }
    .fa-plus-circle {
        color: $medispend-green;
    }
}

.list-options {
    border: 1px solid #A1AFBA;
    border-radius: 3px;
    padding: 5px 16px;
    font-size: 13px;
    line-height: 17px;
    color: #192024;
    width: 100%;
    background: #fff;
    min-height: 29px;
}

.fields-wrapper {
    .dropdown-options {
        max-height: 250px;
        overflow: auto;
        height: auto;
    }
    .inactive-fields {
        border-top: 1px solid $medispend-gray-500;
    }
    .section-fields-container {
        width: 100%;
    }
}

.modal.show {
    .ms-model-body {
        max-height: 80vh;
        .ms-form-input {
            overflow: auto;
        }
        .section-fields-container {
            width: 100%;
        }
    }
    .input-wrapper {
        width: 66%;
    }
    .code-wrapper {
        width: 66%;
        display: flex;
        justify-content: space-between;
        .code {
            width: 20%;
        }
        .label {
            width: 75%;
        }
    }
    .checkbox-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 26px;
        width: 33%;
    }
    .form-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .dropdown-options {
        border: 1px solid $medispend-gray-400;
        .section-field {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .section-fields-container {
        padding: 0;
        .is-editable {
            border-color: $medispend-green;
        }
        .inactive-fields {
            .section-field {
                background-color: $medispend-gray-400;
            }
        }
    }

    .ms-radio-button {
        margin-bottom: 10px;
    }

    .ms-select-button {
        @extend .select-button;
        width: 75%;
        .ms-button {
            padding: 3px 16px;
        }
    }
    .list-type-wrapper {
        display: flex;
        align-items: center;
        .ms-form-group {
            width: 50%;
        }
        .info-message {
            width: 50%;
            color: $medispend-red;
            margin-bottom: 0;
        }
    }
    .info-message {
        color: $medispend-red;
        margin-bottom: 10px;
    }
}

.inactive-fields {
    margin-top: 10px;
    .section-field-read-mode {
        color: $medispend-gray-600;
    }
}

.section-field-read-mode {
    font-size: 13px;
    margin-top: 14px;
    margin-bottom: 14px;
    padding-left: 12px;
}

.overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #000;
    opacity: 0.50;
}
