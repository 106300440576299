@import "../../scss/variables/all";

.ms-toggle {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: $medispend-gray-100;
    border: 1px solid $medispend-gray-100;
    border-radius: 4px;
    &.has-error {
        border: 1px solid $medispend-red;
    }
    &-item {
        input {
            display: none;
        }
    }

    &-column {
        flex-direction: column;
    }

    &.hidden {
        visibility: hidden;
    }

    &-item label {
        display: block;
        color: $medispend-gray-500;
        cursor: pointer;
        padding: 4px 16px;
        font-size: 14px;
        font-weight: 500;
    }

    &-item input:checked + label {
        border-radius: 4px;
        background-color: $medispend-green;
        color: $medispend-white;

        &:hover {
            opacity: 0.8;
        }
    }

    &-item input:disabled + label {
        opacity: 0.6;
        cursor: not-allowed;

        &:hover {
            opacity: 0.6;
        }
    }

}
