@import "../../scss/variables/all";

.ms-form-multi-select {
    .ms-button-title {
        font-size: 14px;
        color: #192024;
        font-weight: 500;
    }
    .ms-button-title.is-placeholder {
        color: $medispend-gray-500;
    }
    .dropdown-menu {
        transform: none!important;
        &.show {
            inset: 40px auto auto 0!important;
        }
        .dropdown-item {
            font-size: 14px;
            padding-left: 24px;
            padding-right: 16px;
            .ms-checkbox-input {
                margin-right: 0;
            }
            .ms-checkbox {
                padding: 0;
            }
        }
    }
}
