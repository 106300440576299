@import "../../scss/variables/all";

.ms-form-group {
    .dynamic-section {
        .ms-form-element {
            display: flex;
            align-items: flex-end;
        }
    }
}
