.readonly {
  display: flex;
  justify-content: space-between;
  gap: 8px;

    .value {
        padding-top: 7px;
        padding-bottom: 7px;
    }
}
